import React from 'react';
import './App.css';

export class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = { helloworld: null, loading: true };
    }

    componentDidMount() {
        this.populateData();
    }

    async getData(url) {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    };

    async populateData() {
        const data = await this.getData(process.env.REACT_APP_API_URL + "/test");
        this.setState({ helloworld: data, loading: false });
    }

    render() {
        let contents = this.state.loading ? <p><em>Loading...</em></p> : this.state.helloworld.message;
        return (<div id="helloworld">{contents}</div>);
    }
}

export default App;
